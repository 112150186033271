<!-- 班级登记管理新增 -->
<template>
    <div class="AddClassRegister">
        <!-- 上面部分 -->
        <div class="top">
            <span style="font-size: 16px; font-weight: bold;">班级登记管理新增</span>
            <el-button class="el-btn" size="small" @click="goBack">
                返回
            </el-button>
        </div>
        <!-- 表单 -->
        <el-form ref="form" :model="form" :rules="rules" id="form-1" class="" inline label-width="150px">
            <el-form-item label="安排单号">
                <el-input v-model="form.planNum" placeholder=""></el-input>
            </el-form-item>
            <el-form-item label="助记码">
                <el-input v-model="form.mnemonic" placeholder="请输入助记码"></el-input>
            </el-form-item>
            <el-form-item label="相关班级" prop="relevant">
                <el-select v-model="form.relevant" filterable placeholder="请输入并选择相关班级" :clearable="true">
                    <el-option
                      v-for="item in form.relevantOpt"
                      :key="item.id"
                      :label="item.class_name"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="培训班级" prop="trainClass">
                <el-select v-model="form.trainClass" filterable placeholder="请输入并选择培训班级" :clearable="true">
                    <el-option
                      v-for="item in form.trainClassOpt"
                      :key="item.id"
                      :label="item.class_name"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="授课老师">
                <el-input  v-model="form.teacher" placeholder="请输入授课老师"></el-input>
            </el-form-item>
            <el-form-item label="安排日期" prop="planDate">
                <el-date-picker
                value-format="yyyy-MM-dd"
                  v-model="form.planDate"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="班级名称" >
                <el-input v-model="form.className" placeholder="请输入班级名称"></el-input>
            </el-form-item>
            <el-form-item label="学科" >
                <el-input v-model="form.subject" placeholder="请输入学科"></el-input>
            </el-form-item>
            <el-form-item label="开班日期" >
                <el-date-picker
                value-format="yyyy-MM-dd"
                  v-model="form.openDate"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="结业日期" >
                <el-date-picker
                value-format="yyyy-MM-dd"
                  v-model="form.graduation"
                  type="date"
                  placeholder="选择日期">
                </el-date-picker>
            </el-form-item>
            <el-form-item label="标准学杂费" >
                <el-input v-model="form.standard" placeholder="请输入标准学杂费"></el-input>
            </el-form-item>
            <el-form-item label="其中学费">
                <el-input v-model="form.tuition" placeholder="请输入其中学费"></el-input>
            </el-form-item>
            <el-form-item label="杂费">
                <el-input v-model="form.incidental" placeholder="请输入杂费"></el-input>
            </el-form-item>
            <el-form-item label="授课次数">
                <el-input v-model="form.frequency" placeholder="请输入授课次数"></el-input>
            </el-form-item>
            <el-form-item label="计划招生人数">
                <el-input v-model="form.project" placeholder="请输入计划招生人数"></el-input>
            </el-form-item>
            <el-form-item label="老师联系电话" prop="telephone">
                <el-input v-model="form.telephone" placeholder="请输入老师联系电话"></el-input>
            </el-form-item>
            <el-form-item label="上课地点" >
                <el-input v-model="form.locale" placeholder="请输入上课地点"></el-input>
            </el-form-item>
            <el-form-item label="实际人数">
                <el-input v-model="form.actual" placeholder="请输入实际人数"></el-input>
            </el-form-item>
            <el-form-item label="录入人员">
                <el-select v-model="form.enterPeople" placeholder="请输入并选择录入人员" :clearable="true">
                    <el-option
                      v-for="item in form.enterPeopleOpt"
                      :key="item.id"
                      :label="item.fullname"
                      :value="item.id">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="班级状态">
                <el-select v-model="form.classState" placeholder="请选择状态" :clearable="true">
                    <el-option
                      v-for="item in form.classStateOpt"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                    </el-option>
                  </el-select>
            </el-form-item>
            <el-form-item label="课时安排说明">
                <el-input v-model="form.explain" type="textarea" 
                class="textArea-width" placeholder="请输入"></el-input>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- 底部 提交与取消 -->
        <div slot="footer" class="dialog-footer">
            <el-button style="background-color: #18BC9C;color: #FFFFFF;" @click="submitForm('form')">提 交</el-button>
            <el-button class="btn" @click="resetForm('form')">取 消</el-button>
        </div>
    </div>
</template>

<script>
    export default{
        data() {
            return{
                form:{
                  planNum:"",//安排单号
                  mnemonic:"",//助记编号
                  relevant:"",//相关班级
                  relevantOpt:[],//相关班级选项
                  trainClass:"",//培训班级
                  trainClassOpt:[],//培训班级选项
                  teacher:"",//授课老师
                  planDate:"",//安排日期
                  className:"",//班级名称
                  subject:"",//学科
                  openDate:"",//开班日期
                  graduation:"",//结业日期
                  standard:"",//标准学杂费
                  tuition:"",//其中学费
                  incidental:"",//杂费
                  frequency:"",//授课次数
                  project:"",//计划招生人数
                  telephone:"",//老师联系电话
                  locale:"",//上课地点
                  actual:"",//实际人数
                  enterPeople:"",//录入人员
                  enterPeopleOpt:[],//录入人员选项
                  classState:1,//班级状态
                  classStateOpt:[
                      {label: "开班", value: 1},
                      {label: "取消", value: 2},
                      {label: "结业", value: 3},
                  ],//班级状态选择
                  explain:"",//课时安排说明
                  
                },
                rules:{
                    relevant:[
                         { required: true, message: '相关班级不能为空', trigger: 'change' },
                    ],
                    trainClass:[
                        { required: true, message: '培训班级不能为空', trigger: 'change' }
                    ],                    
                    planDate:[
                        { type: 'date', required: true, message: '安排日期不能为空', trigger: 'change' }
                    ],
                    telephone:[
                        { required: true, message: '老师联系电话不能为空', trigger: 'blur' }
                    ],
                },
                bool:false
            }
        },
        created() {
            // 相关班级数据 ,培训班级数据
            this.$request({
                url:'/api/class_/list',
                method:'POST',
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                console.log(res,'班级数据')
                if(res.code==1){
                    this.form.relevantOpt=res.data.list//相关班级数据
                    this.form.trainClassOpt=res.data.list//培训班级数据
                }
            })
            
            // 录入人员数据
            this.$request({
                url:'/api/humanresources/list',
                method:'POST',
                data:{
                    page:1,
                    limit:100
                }
            }).then(res=>{
                console.log(res)
                if(res.code==1){
                    this.form.enterPeopleOpt=res.data.list
                }
            })
        },
        methods:{
            goBack() { //返回
                this.$router.go(-1);
            },
            submitForm(form) { //新增提交
                this.$refs[form].validate((valid) => {
                    if (valid) {
                        // alert('submit!');
                        console.log(this.form)
                        if(this.bool){
                            return
                        }
                        this.bool=true
                        let form = this.form
                        this.$request({
                            url:'/api/Classmanagement/add',
                            method:'POST',
                            data:{
                                number:form.planNum,//安排单号
                                code:form.mnemonic,//助记码
                                relevant_class_id:form.relevant,//相关班级id
                                class_id:form.trainClass,//培训班级id
                                resources:form.teacher,//授课老师
                                arrange_time:form.planDate,//安排日期
                                class_name:form.className,//班级名称
                                course:form.subject,//学科
                                start_time:form.openDate,//开班时间
                                end_time:form.graduation,//结业时间
                                tuition_incidentals:form.standard,//学杂费
                                tuition:form.tuition,//学费
                                incidentals:form.incidental,//杂费
                                lecture_number:form.frequency,//授课次数
                                planned_size:form.project,//计划人数
                                actual_size:form.actual,//实际人数
                                teacher_mobile:form.telephone,//教师联系电话
                                classroom:form.locale,//上课地点
                                add_id:form.enterPeople,//录入人id
                                class_status:form.classState,//班级状态
                                class_arrangement:form.explain//课时安排说明
                            }
                        }).then(res=>{
                            if(res.code==1){
                                this.$message({
                                    message: '新增列表成功',
                                    type: 'success'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }else{
                                this.$message({
                                    message: res.msg,
                                    type: 'error'
                                })
                                setTimeout(() => {
                                    this.goBack()
                                }, 1500)
                            }
                        }).catch(()=>{
                            this.bool=false
                        })
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            resetForm(form) { //取消
                this.goBack()
                this.$refs[form].resetFields();
            },
        }
    }
</script>

<style scoped="scoped">
    .AddClassRegister{
        width: 100%;
        height: 100%;
    }
    /* 上面部分 */
    .top {
        padding-top: 25px;
        margin: 0px 24px 20px 28px;
        display: flex;
        justify-content: space-between;
    }
    
    .el-btn {
        background: #18BC9C;
        padding-left: 25px;
        background-position: 5px 7px;
        display: inline-block;
        background-image: url(../../assets/back.png);
        background-repeat: no-repeat;
        color: #FFFFFF;
    }
    /* 表单部分 */
    .el-form {
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
    }
    
    ::v-deep .el-form-item__label {
        color: #000000;
    }
    
    .el-input {
        width: 300px;
        height: 37px !important;
    }
    
    .el-select {
        width: 300px;
    }
    
    ::v-deep .el-input.is-disabled .el-input__inner {
        background-color: #FFFFFF;
    }
    /* 底部提交 取消 */
    .dialog-footer {
        text-align: center;
        padding-bottom: 20px;
    }
    
    .btn:hover {
        color: #666;
        border-color: #ccc;
        background-color: #FFFFFF;
    }
</style>
